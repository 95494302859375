import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Grid, Container, Paper } from '@material-ui/core';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';

import { PageLayout } from '../layouts';
import { Box } from '../components';
import { truncateStringSeoFriendly } from '../helpers/truncate-string';

function MultiContentPageTemplate({ data, pageContext, path }) {
  const { images } = data;
  const { body, childrenData, excerpt, keywords, title, contentImages } = pageContext;
  const mainImage = images.nodes.find((f) => f.childImageSharp.fluid.originalName === contentImages[0]);

  return (
    <PageLayout
      description={truncateStringSeoFriendly(excerpt)}
      title={title}
      image={mainImage ? mainImage.childImageSharp.resize : null}
      keywords={keywords}
      pathnames={[path, ...childrenData.map(({ path: childPaths }) => childPaths)]}
    >
      <Box marginClass="marginBottom" bgColorClass="contrastText">
        <Container maxWidth="lg" style={{ paddingTop: '5vh', paddingBottom: '5vh' }}>
          <Grid container direction="row" justify="space-evenly" alignItems="center">
            <Grid item lg={6} xs={12}>
              <Paper elevation={4}>
                <Img fluid={mainImage.childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%" />
              </Paper>
            </Grid>
            <Grid item lg={4} xs={12}>
              <MDXRenderer linkProps={{ path }} title={title} typographyProps={{ colorClass: 'primary' }}>
                {body}
              </MDXRenderer>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {childrenData.map((child, i) => {
        const isOdd = i & 1;
        const colorClass = isOdd ? 'contrastText' : 'primary';
        const textColor = isOdd ? 'primary' : 'contrastText';
        const image = images.nodes.find((f) => child.contentImages.includes(f.childImageSharp.fluid.originalName));

        return (
          <Box key={child.id} bgColorClass={colorClass} bgPattern={!isOdd ? child.bgPattern : null}>
            <Container maxWidth="lg" style={{ paddingTop: '15vh', paddingBottom: '15vh' }}>
              {image ? (
                !isOdd ? (
                  <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item lg={4} xs={12}>
                      <MDXRenderer title={child.title} linkProps={{ path: child.path }} typographyProps={{ colorClass: textColor }}>
                        {child.body}
                      </MDXRenderer>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Paper elevation={4}>
                        <Img fluid={image.childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%" />
                      </Paper>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item lg={6} xs={12}>
                      <Paper elevation={4}>
                        <Img fluid={image.childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%" />
                      </Paper>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <MDXRenderer title={child.title} linkProps={{ path: child.path }} typographyProps={{ colorClass: textColor }}>
                        {child.body}
                      </MDXRenderer>
                    </Grid>
                  </Grid>
                )
              ) : (
                <Grid container direction="row" justify="space-evenly" alignItems="center">
                  <Grid item xs={12}>
                    <MDXRenderer typographyProps={{ colorClass: textColor, align: 'center' }}>{child.body}</MDXRenderer>
                  </Grid>
                </Grid>
              )}
            </Container>
          </Box>
        );
      })}
    </PageLayout>
  );
}

export const query = graphql`
  query MultiContentPageTemplateQuery($contentImages: [String!]) {
    images: allFile(filter: { relativePath: { in: $contentImages } }) {
      nodes {
        id
        childImageSharp {
          fluid(quality: 100) {
            originalName
            ...GatsbyImageSharpFluid
          }
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
  }
`;

MultiContentPageTemplate.propTypes = {
  images: PropTypes.array,
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

MultiContentPageTemplate.defaultProps = { images: null };

export default MultiContentPageTemplate;
